import React, { useState, useEffect } from 'react'
import { Box, Button, useToast } from '@chakra-ui/react'
import { FetchEntries } from '../Queries/Queries'
import { useNavigate } from 'react-router-dom'
import { Queryparams } from '../Constants/Constants'
import TablerEntries from './TablerEntries'

// const dummyhistory = [
//     {
//         "name": "BhL TryTell",
//         "tcode": "mkHU3B7SR",
//         "aptr": "102523-wvc5sxlo",
//         "drwdate": "2023-10-25",
//         "gname": "3D1",
//         "gperiod": "2PM",
//         "appsid": "jZQh",
//         "payload": "998-1 ",
//         "c_at": "2023-10-25 09:16:58",
//         "u_at": "2023-10-25 09:16:58",
//         "tot": 1,
//         "rawresp": [
//             {
//                 "com": "998",
//                 "bet": "1",
//                 "not": [
//                     {
//                         "com": "998",
//                         "bet": 1,
//                         "not": "OK",
//                         "prz": 550
//                     }
//                 ],
//                 "tot": 1
//             }
//         ],
//         "void": false,
//         "tcont": "0"
//     },
//     {
//         "name": "BhL TryTell",
//         "tcode": "D83yqB7Sg",
//         "aptr": "102523-rrwf5vmr",
//         "drwdate": "2023-10-25",
//         "gname": "3D1",
//         "gperiod": "2PM",
//         "appsid": "iEDX",
//         "payload": "999-1 ",
//         "c_at": "2023-10-25 09:16:48",
//         "u_at": "2023-10-25 09:16:48",
//         "tot": 1,
//         "rawresp": [
//             {
//                 "com": "999",
//                 "bet": "1",
//                 "not": [
//                     {
//                         "com": "999",
//                         "bet": 1,
//                         "not": "OK",
//                         "prz": 550
//                     }
//                 ],
//                 "tot": 1
//             }
//         ],
//         "void": false,
//         "tcont": "0"
//     },
//     {
//         "name": "BhL TryTell",
//         "tcode": "J4SkCf7SR",
//         "aptr": "102523-vtzebnyb",
//         "drwdate": "2023-10-25",
//         "gname": "3D1",
//         "gperiod": "2PM",
//         "appsid": "Wy98",
//         "payload": "998-499 999-501 ",
//         "c_at": "2023-10-25 08:46:55",
//         "u_at": "2023-10-25 08:46:55",
//         "tot": 499,
//         "rawresp": [
//             {
//                 "com": "998",
//                 "bet": "499",
//                 "not": [
//                     {
//                         "com": "998",
//                         "bet": 499,
//                         "not": "OK",
//                         "prz": 274450
//                     }
//                 ],
//                 "tot": 499
//             },
//             {
//                 "com": "999",
//                 "bet": "501",
//                 "not": [
//                     {
//                         "com": "999",
//                         "bet": 0,
//                         "not": "MX"
//                     }
//                 ],
//                 "tot": 0
//             }
//         ],
//         "void": false,
//         "tcont": "0"
//     },
//     {
//         "name": "BhL TryTell",
//         "tcode": "L09r9B7IR",
//         "aptr": "102523-yh8hu756",
//         "drwdate": "2023-10-25",
//         "gname": "3D1",
//         "gperiod": "2PM",
//         "appsid": "Pm5e",
//         "payload": "999-1 ",
//         "c_at": "2023-10-25 08:44:33",
//         "u_at": "2023-10-25 08:44:33",
//         "tot": 1,
//         "rawresp": [
//             {
//                 "com": "999",
//                 "bet": "1",
//                 "not": [
//                     {
//                         "com": "999",
//                         "bet": 1,
//                         "not": "OK",
//                         "prz": 550
//                     }
//                 ],
//                 "tot": 1
//             }
//         ],
//         "void": false,
//         "tcont": "0"
//     }
// ]

const EntriesForm = ({ searchtcode, searchname, searchdate, selGame, token, newEntry, realtime }) => {
    const [entries, setentries] = useState([])
    const navigate = useNavigate()
    const toast = useToast()
    const [page, setpage] = useState(1)

    const getEntries = () => {
        setentries([])
        FetchEntries(selGame.gcode, searchdate, searchtcode, searchname, page, Queryparams.url, token).then(res => {
            if (res.data) {
                setentries(res.data)
            } else if (res.status === 401) {
                localStorage.removeItem("reduxPersistAuth")
                navigate("/login")
            }
        }).catch(() => {
            setentries([])
            toast({
                title: "FETCH ENTRIES ERROR!",
                description: "Ooops! There is something wrong fetching entries.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
        // setentries(dummyhistory)
    }

    useEffect(() => {
        if (realtime) {
            entries.pop()
            setentries([newEntry, ...entries])
        }
    }, [newEntry])


    useEffect(() => {
        getEntries()
    }, [selGame.gcode, searchdate, searchtcode, searchname, page, realtime])

    return (
        <Box border={"1px solid blue"} w={"800px"} ml={5} borderRadius={5}>
            <TablerEntries
                entries={entries}
            />
            <Box display={"flex"} flexDir={"row"} p={2} justifyContent={'center'}>
                <Button onClick={() => { page > 1 && setpage(page - 1) }}>{"<-"}</Button>
                <Button w={50} marginInline={1} onClick={() => setpage(1)}>{page}</Button>
                <Button onClick={() => setpage(page + 1)}>{"->"}</Button>
            </Box>
        </Box>
    )
}

export default EntriesForm